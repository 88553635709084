import { Button } from "../../../components/Button";
import { Form } from "../../../components/Form";
import { useLogin } from "../../../controllers/loginController";
import { FormView } from "./styles";

export const Login = () => {
  const {
    handleSubmit,
    form: {
      register,
      formState: {
        errors,
        isValid,
      }
    },
    registerWithMask,
  } = useLogin();

  return (
    <FormView>
      <Form onSubmit={handleSubmit}>
        <div className="inputs-view">
          <div className="input-view">
            <label className="input-label" htmlFor="cpfcnpj">CPF/CNPJ</label>

            <input
              type="text"
              id="cpfcnpj"
              autoComplete="off"
              {...registerWithMask("cpfcnpj", ["999.999.999-99", "99.999.999/9999-99"], {
                showMaskOnFocus: false,
                showMaskOnHover: false,
                greedy: true
              })}
              placeholder="CPF/CNPJ"
            />

            <span className="input-error-message">{ errors.cpfcnpj?.message ?? ""}</span>
          </div>

          <div className="input-view">
            <label className="input-label" htmlFor="senha">Senha</label>

            <input
              type="password"
              id="senha"
              autoComplete="off"
              {...register("senha")}
              placeholder="Senha"
            />

            <span className="input-error-message">{ errors.senha?.message ?? ""}</span>
          </div>
        </div>

        <div
          className="actions-view"
        >
          <Button
            disabled={!isValid}
          >
            Entrar
          </Button>
        </div>
      </Form>
    </FormView>
  );
};
