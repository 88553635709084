import styled from "styled-components";

export const AuthLayoutView = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: ${({ theme }) => theme.spacing.very_large};
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
`;

export const Logo = styled.img`
  height: 72px;
`;

export const Content = styled.section`
  display: flex;
  flex: 1;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.medium};
`;

export const InstallAppNotificationView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.medium };
  background-color: ${({ theme }) => theme.colors.primary.default };
  width: 100%;
  font-weight: 500;

  & > span {
    min-height: ${({ theme }) => theme.font_size.small };
    font-size: ${({ theme }) => theme.font_size.small };
    line-height: ${({ theme }) => theme.font_size.small };
  }

  & > button {
    border: 1px solid #FCFBFD;
    padding: ${({ theme }) => `${ theme.spacing.very_small } ${ theme.spacing.medium }`};
    background-color: ${({ theme }) => theme.colors.primary.default };
    min-height: ${({ theme }) => theme.font_size.small };
    font-size: ${({ theme }) => theme.font_size.small };
    color: #FCFBFD;
    font-weight: 700;
    border-radius: ${({ theme }) => theme.border_radius.medium };
    cursor: pointer;
  }
`;
