import { Outlet } from "react-router-dom";

import logo from "../../../assets/logo.png";

import { AuthLayoutView, Content, InstallAppNotificationView, Logo } from "./styles";
import { Button } from "../../Button";
import { usePwa } from "@dotmind/react-use-pwa";

export const AuthLayout = () => {
  const {
    installPrompt,
    isInstalled,
    isStandalone,
    canInstall,
    userChoice
  } = usePwa();

  return (
    <AuthLayoutView>
      <Logo src={ logo } />

      <Content>
        <Outlet />
      </Content>

      {
        ( !isInstalled || !isStandalone ) && canInstall && userChoice !== 'accepted'
          ? (
              <InstallAppNotificationView>
                <span>Deseja adicionar este app a tela inicial?</span>

                <button onClick={installPrompt}>
                  Sim
                </button>
              </InstallAppNotificationView>
            )
          : <></>
      }
    </AuthLayoutView>
  );
};
